<template>
    <div class="row">
        <div class="col-12">
            <loader :show="generating"></loader>
            <div v-if="!generating">

                <ul v-if="errors.length > 0">
                    <li v-for="e in errors" class="tx-danger">{{e}}</li>
                </ul>

                <table v-show="sites.length > 0" width="100%" class="table table-striped table-hover tx-white">
                    <thead class="thead-colored thead-primary">
                    <tr>
                        <th>PRODUCT</th>
                        <th>COUNT</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="s in sites">
                        <td>{{s.name}}</td>
                        <td>{{s.count}}</td>
                    </tr>
                    </tbody>
                </table>

            </div>

        </div>
    </div>
</template>

<script>
import Loader from "@/components/Loader";
import axios from 'axios';
import authHeader from '@/services/auth-header';
export default {
    name: "Sites",
    components: {Loader},
    data(){
        return {
            generating: false,
            errors: [],
            sites: []
        }
    },
    mounted(){
        axios.get(`https://api.varsanpr.com/api/reports/sites?client_id=${this.$store.state.auth.user.selectedClient}`, {
            headers: authHeader()
        })
            .then(response => {
                this.sites = response.data.sites;
                this.errors = response.data.errors || [];
                this.generating = false;
            })
            .catch(error => {
                this.generating = false;
                this.errors.push("There was an issue generating the report. Please try again later!");
            });
    }
}
</script>

<style scoped>

</style>

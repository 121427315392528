<template>
  <div class="row">
     <div class="col-12">
         <loader :show="generating"></loader>
         <div v-if="!generating">

             <table width="100%" class="table table-striped table-hover tx-white">
                 <thead class="thead-colored thead-primary">
                     <tr>
                         <th>Status</th>
                         <th>Amount</th>
                         <th>Percentage</th>
                     </tr>
                 </thead>
                 <tbody>
                     <tr v-for="claim in claims">
                          <td>
                            {{claim.status}}
                            <button class="btn btn-sm btn-primary mg-l-10" v-if="!['Debt Collection', 'At DVLA', 'Paid', 'Paid on Site', 'Cancelled', 'AUTOCANCELLATION', 'HOLDING', 'Sent to Keeper', 'Second Letter Sent', 'Clerical Records', 'Final Reminder'].includes(claim.status)" @click="openStatus(claim.status)">Show Me</button>
                          </td>
                          <td>{{claim.count.toLocaleString()}}</td>
                          <td>{{claim.percentage}}%</td>
                     </tr>
                 </tbody>

             </table>    

         </div>
     </div>
 </div>
</template>

<script>
import axios from 'axios';
import authHeader from '@/services/auth-header';
import Loader from '@/components/Loader';

export default {
 name: 'OpenClaims',
  components: {
    Loader
  },
 data(){
     return {
         claims: [],
         generating: true
     }
 },
 mounted(){
     this.getOpenClaims();  
 },
 methods: {
  getOpenClaims(){
      axios.get(`https://api.varsanpr.com/api/misc/openclaims`, {
        headers: authHeader()
      })
      .then(response => {
        let claims = response.data.claims;
        let statuses = Object.keys(claims);
        let total = 0;
        statuses.forEach((s) => {
          total += claims[s];
        });
        this.claims = [];
        statuses.forEach((s) => {
          this.claims.push({
            status: s,
            count: claims[s],
            percentage: Math.round((claims[s] / total) * 100)
          });
        });

        this.claims = this.claims.sort((a, b) => {
          return a.count < b.count ? 1 : -1;
        });
        this.generating = false;
      })
      .catch(error => {
        console.log(error);
        this.$error(error.response?.data?.message || error?.message || error, error);
      });
    },
    openStatus(status){
      // Open url in new tab
      window.open(window.location.origin + `/globalclaims/${status}`, '_blank');
    }
 },
}
</script>
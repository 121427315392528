<template>
    <div>

        <div class="row">
            <div class="col-12">
                <div class="card bd-1 bd-white-1 pd-10">
                    <div class="card-header bg-transparent pd-0 bd-b-0">
                        <h5 class="tx-14 tx-uppercase tx-spacing-1 tx-semibold">Filter Report</h5>
                    </div><!-- card-header -->
                    <div class="card-block pd-0">

                        <div class="row row-sm mg-t-10">

                            <div class="col-3 mg-t-10 mg-lg-t-0">
                                <div class="form-group mg-b-0">
                                    <label>Start Date</label>
                                    <input type="date" v-model="filters.start_date" class="form-control" placeholder="DD/MM/YYYY">
                                </div><!-- form-group -->
                            </div><!-- col-2 -->
                            <div class="col-3 mg-t-10 mg-lg-t-0">
                                <div class="form-group mg-b-0">
                                    <label>End Date</label>
                                    <input type="date" v-model="filters.end_date" class="form-control" placeholder="DD/MM/YYYY">
                                </div><!-- form-group -->
                            </div><!-- col-2 -->

                        </div>

                        <a href="javascript:void(0);" :disabled="generating" @click="generate" class="btn btn-info tx-11 tx-spacing-1 tx-uppercase tx-semibold tx-mont pd-y-12 pd-x-30 mg-t-10">Generate Report</a>

                    </div>
                </div>
            </div>
        </div>

        <div class="row mg-t-20">
            <div class="col-12">

                <div>

                    <p v-if="generating">Searching...</p>

                    <p>Found {{ results.length }} claims...</p>

                    <button v-if="results.length > 0 && !generating" @click="exportReport" class="btn btn-primary">Export</button>

                    <table class="table">
                        <thead>
                            <tr>
                                <th>REFERENCE</th>
                                <th>REGISTRATION</th>
                                <th>DATE POSTED DVLA</th>
                                <th>INCIDENT TIMESTAMP</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="results.length < 1">
                                <td colspan="4" class="text-center">No results found.</td>
                            </tr>
                            <tr v-for="result in results">
                                <td>{{ result.reference }}</td>
                                <td>{{ result.registration }}</td>
                                <td>{{ result.date_posted_dvla }}</td>
                                <td>{{ result.incident_timestamp }}</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { DateTime } from "luxon";
import Loader from "@/components/Loader";
import { ExportToCsv } from 'export-to-csv';
import axios from 'axios';
import authHeader from '@/services/auth-header';

export default {
    name: "ClericalRecords",
    components: {
        Loader
    },
    data(){
        return {    
            filters: {
                end_date: null,
                start_date: null
            },
            generating: false,
            results: []
        }
    },
    mounted(){

    },
    methods: {
        async generate(){

            let clients = this.$store.state.auth.user.clients;
            this.results = [];
            this.generating = true;

            for await (let client of clients){

                if(client.id === 1){
                    continue;
                }

                try{
                    let req = {
                        clientId: client.id,
                        startDate: this.filters.start_date,
                        endDate: this.filters.end_date
                    }

                    let response = await axios.get('https://api.varsanpr.com/api/reports/clericalrecords', {
                        params: req,
                        headers: authHeader()
                    });

                    if(response.data.success){

                        response.data.claims.forEach((claim) => {
                            claim.date_posted_dvla = DateTime.fromSeconds(claim.date_posted_dvla).toFormat('dd/MM/yyyy');
                            if(claim.incident_timestamp){
                                claim.incident_timestamp = DateTime.fromSeconds(claim.incident_timestamp).toFormat('dd/MM/yyyy HH:mm');
                            }else{
                                claim.incident_timestamp = 'N/A';
                            }
                        })

                        this.results = this.results.concat(response.data.claims)

                    }
                }catch(err){
                    this.$error(`Failed to search claims for ${client.name}`, err);
                }

            }

            this.generating = false;

        },
        exportReport(){
            let data = [];
            for(let claim of this.results){
                data.push({
                    "Reference": claim.reference,
                    "Registration": claim.registration,
                    "Date Posted DVLA": claim.date_posted_dvla,
                    "Incident Timestamp": claim.incident_timestamp || "N/A"
                });
            }

            const options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: true,
                title: 'VARS Clerical Records Report',
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
            };

            const csvExporter = new ExportToCsv(options);

            csvExporter.generateCsv(data);
        }
    }
}
</script>
<template>
  <div class="row">
     <div class="col-6">
         <loader :show="generating"></loader>
         <div v-if="!generating">

          <h4>
            Canclled for Us not DCBL ({{ cancelledForUsNotDcbl.length  }})
            <button class="btn btn-success btn-sm float-right" @click="exportUsNotDcbl">Export to Excel</button>
          </h4>

             <table width="100%" class="table table-striped table-hover tx-white">
                 <thead class="thead-colored thead-primary">
                     <tr>
                         <th>Reference</th>
                         <th>System Status</th>
                         <th>DCBL Stage</th>
                         <th>DCBL Status</th>
                     </tr>
                 </thead>
                 <tbody>
                     <tr v-for="claim in cancelledForUsNotDcbl">
                          <td>{{ claim.reference }}</td>
                          <td>{{ claim.status }}</td>
                          <td>{{ claim.debt_collection_stage }}</td>
                          <th>{{ claim.debt_collection_status }}</th>
                     </tr>
                 </tbody>

             </table>    

         </div>
     </div>
     <div class="col-6">
      <loader :show="generating"></loader>
      <div v-if="!generating">

        <h4>
          Cancelled for DCBL not Us ({{ cancelledForDcblNotUs.length  }})
          <button class="btn btn-success btn-sm float-right" @click="exportDcblNotUs">Export to Excel</button>
        </h4>

          <table width="100%" class="table table-striped table-hover tx-white">
              <thead class="thead-colored thead-primary">
                  <tr>
                      <th>Reference</th>
                      <th>System Status</th>
                      <th>DCBL Stage</th>
                      <th>DCBL Status</th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="claim in cancelledForDcblNotUs">
                       <td>{{ claim.reference }}</td>
                       <td>{{ claim.status }}</td>
                       <td>{{ claim.debt_collection_stage }}</td>
                       <th>{{ claim.debt_collection_status }}</th>
                  </tr>
              </tbody>

          </table>    

      </div>
     </div>
 </div>
</template>

<script>
import axios from 'axios';
import authHeader from '@/services/auth-header';
import Loader from '@/components/Loader';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export default {
 name: 'Dcbl',
  components: {
    Loader
  },
 data(){
     return {
         cancelledForUsNotDcbl: [],
         cancelledForDcblNotUs: [],
         generating: true
     }
 },
 mounted(){
     this.getOpenClaims();  
 },
 methods: {
  getOpenClaims(){
      axios.get(`https://api.varsanpr.com/api/reports/dcbl`, {
        headers: authHeader()
      })
      .then(response => {
        this.cancelledForDcblNotUs = response.data.cancelledForDcblNotUs;
        this.cancelledForUsNotDcbl = response.data.cancelledForUsNotDcbl;
        this.generating = false;
      })
      .catch(error => {
        console.log(error);
        this.$error(error.response?.data?.message || error?.message || error, error);
      });
    },
    openStatus(status){
      // Open url in new tab
      window.open(window.location.origin + `/globalclaims/${status}`, '_blank');
    },
    exportUsNotDcbl(){
      const workbook = new ExcelJS.Workbook();
      workbook.creator = "VARS Technology LTD";
      workbook.lastModifiedBy = "VARS Technology LTD";
      workbook.created = new Date();
      workbook.modified = new Date();

      const sheet = workbook.addWorksheet("Cancelled for Us not DCBL");

      let columns = [
        {
          header: "Reference",
          key: "reference",
        },
        {
          header: "System Status",
          key: "status",
        },
        {
          header: "DCBL Stage",
          key: "debt_collection_stage",
        },
        {
          header: "DCBL Status",
          key: "debt_collection_status",
        },
      ]

      sheet.columns = columns;

      this.cancelledForUsNotDcbl.forEach((claim) => {
        sheet.addRow(claim);
      });

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        saveAs(blob, "Cancelled for Us not DCBL.xlsx");
      });
    },
    exportDcblNotUs(){
      const workbook = new ExcelJS.Workbook();
      workbook.creator = "VARS Technology LTD";
      workbook.lastModifiedBy = "VARS Technology LTD";
      workbook.created = new Date();
      workbook.modified = new Date();

      const sheet = workbook.addWorksheet("Cancelled for DCBL not Us");

      let columns = [
        {
          header: "Reference",
          key: "reference",
        },
        {
          header: "System Status",
          key: "status",
        },
        {
          header: "DCBL Stage",
          key: "debt_collection_stage",
        },
        {
          header: "DCBL Status",
          key: "debt_collection_status",
        },
      ]

      sheet.columns = columns;

      this.cancelledForDcblNotUs.forEach((claim) => {
        sheet.addRow(claim);
      });

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        saveAs(blob, "Cancelled for DCBL not Us.xlsx");
      });
    }
 },
}
</script>
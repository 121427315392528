<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="card bd-0">
                    <div class="card-header bg-dark" style="background-color: #141d28 !important;">
                        <ul class="nav nav-tabs nav-tabs-for-dark card-header-tabs">
                            <li v-if="$can('reports-claims-view')" class="nav-item">
                                <a class="nav-link bd-0 pd-y-8" @click="toggleTab('claims')" :class="{'active show': tabs.claims}" href="javascript:void(0);">Claims</a>
                            </li>
                            <li v-if="$can('reports-claims-view')" class="nav-item">
                                <a class="nav-link bd-0 tx-gray-light" @click="toggleTab('statuses')" :class="{'active show': tabs.statuses}" href="javascript:void(0);">Claim Statuses</a>
                            </li>
                            <li v-if="$can('reports-sites-view')" class="nav-item">
                                <a class="nav-link bd-0 tx-gray-light" @click="toggleTab('sites')" :class="{'active show': tabs.sites}" href="javascript:void(0);">Sites</a>
                            </li>
<!--                            <li v-if="$can('reports-invoices-view')" class="nav-item">-->
<!--                                <a class="nav-link bd-0 tx-gray-light" v-bind:class="{'active show': !$can('reports-invoices-view')}" href="#invoices" data-toggle="tab">Unpaid Invoices</a>-->
<!--                            </li>-->
<!--                            <li v-if="$can('reports-inout-view')" class="nav-item">-->
<!--                                <a class="nav-link bd-0 tx-gray-light" v-bind:class="{'active show': !$can('reports-claims-view')}" href="#inout" data-toggle="tab">In/Out</a>-->
<!--                            </li>-->
<!--                            <li class="nav-item">-->
<!--                                <a class="nav-link bd-0 tx-gray-light" href="#blacklist" data-toggle="tab">Blacklist</a>-->
<!--                            </li>-->
                            <li v-if="$can('claims-process')" class="nav-item">
                                <a class="nav-link bd-0 tx-gray-light" @click="toggleTab('atdvla')" :class="{'active show': tabs.atdvla}" href="javascript:void(0);">At DVLA</a>
                            </li>
                            <li v-if="$can('claims-process')" class="nav-item">
                                <a class="nav-link bd-0 tx-gray-light" @click="toggleTab('clericalrecords')" :class="{'active show': tabs.clericalrecords}" href="javascript:void(0);">Clerical Records</a>
                            </li>
                            <li v-if="$can('claims-process')" class="nav-item">
                                <a class="nav-link bd-0 tx-gray-light" @click="toggleTab('openclaims')" :class="{'active show': tabs.openclaims}" href="javascript:void(0);">Open Claims</a>
                            </li>
                            <li v-if="$can('claims-process')" class="nav-item">
                                <a class="nav-link bd-0 tx-gray-light" @click="toggleTab('dcbl')" :class="{'active show': tabs.dcbl}" href="javascript:void(0);">DCBL</a>
                            </li>
                        </ul>
                    </div><!-- card-header -->
                    <div class="card-body color-gray-lighter bd bd-t-0 rounded-bottom">
                        <div class="tab-content">
                            <div v-if="$can('reports-claims-view')" class="tab-pane" :class="{'active show': tabs.claims}" id="claims">
                                <claims :site="this.site" :type="this.type" :paid="this.paid" :outstanding="this.outstanding" :previous="this.previous" :current="this.current" :types="this.types"></claims>
                            </div><!-- tab-pane -->
                            <div v-if="$can('reports-sites-view')" class="tab-pane" :class="{'active show': tabs.sites}" id="sites">
                                <sites></sites>
                            </div><!-- tab-pane -->
                            <div v-if="$can('reports-claims-view')" class="tab-pane" :class="{'active show': tabs.statuses}" id="statuses">
                                <ClaimStatuses></ClaimStatuses>
                            </div><!-- tab-pane -->
<!--                            <div v-if="$can('reports-invoices-view')" class="tab-pane" id="invoices">-->
<!--                                <invoices></invoices>-->
<!--                            </div>&lt;!&ndash; tab-pane &ndash;&gt;-->
<!--                            <div v-if="$can('reports-inout-view')" v-bind:class="{'active show': !$can('reports-claims-view')}" class="tab-pane" id="inout">-->
<!--                                <in-out></in-out>-->
<!--                            </div>&lt;!&ndash; tab-pane &ndash;&gt;-->
<!--                            <div class="tab-pane" id="blacklist">-->

<!--                            </div>&lt;!&ndash; tab-pane &ndash;&gt;-->
                            <div v-if="$can('claims-process')" class="tab-pane" :class="{'active show': tabs.atdvla}" id="atdvla">
                               <AtDvla></AtDvla>
                            </div><!-- tab-pane -->
                            <div v-if="$can('claims-process')" class="tab-pane" :class="{'active show': tabs.clericalrecords}" id="clericalrecords">
                                <ClericalRecords></ClericalRecords>
                             </div><!-- tab-pane -->
                             <div v-if="$can('claims-process')" class="tab-pane" :class="{'active show': tabs.openclaims}" id="openclaims">
                                <OpenClaims></OpenClaims>
                            </div><!-- tab-pane -->
                            <div v-if="$can('claims-process')" class="tab-pane" :class="{'active show': tabs.dcbl}" id="dcbl">
                                <Dcbl></Dcbl>
                            </div><!-- tab-pane -->
                        </div><!-- tab-content -->
                    </div><!-- card-body -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Claims from "./Claims";
import Sites from './Sites';
import ClaimStatuses from "./ClaimStatuses.vue";
import AtDvla from "./AtDvla.vue";
import ClericalRecords from "./ClericalRecords.vue";
import OpenClaims from "./OpenClaims.vue";
import Dcbl from "./Dcbl.vue";
export default {
    name: "Reports",
    components: {
        Claims,
        Sites,
        ClaimStatuses,
        AtDvla,
        ClericalRecords,
        OpenClaims,
        Dcbl
    },
    props: {
        type: {
            type: String,
            required: false,
            default: ''
        },
        types: {
            type: Array,
            required: false,
            default: []
        },  
        site: {
            type: Number,
            required: false,
            default: null
        },
        previous: {
            type: Boolean,
            required: false,
            default: false
        },
        current: {
            type: Boolean,
            required: false,
            default: false
        },
        paid: {
            type: Boolean,
            required: false,
            default: false
        },
        outstanding: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    data() {
        return {
            tabs: {
                claims: true,
                sites: false,
                statuses: false,
                invoices: false,
                inout: false,
                blacklist: false,
                atdvla: false,
                clericalrecords: false,
                openclaims: false,
                dcbl: false
            }
        }
    },
    methods: {
        toggleTab(tab) {
            this.tabs = {
                claims: false,
                sites: false,
                statuses: false,
                invoices: false,
                inout: false,
                blacklist: false,
                atdvla: false,
                clericalrecords: false,
                openclaims: false,
                dcbl: false
            };
            this.tabs[tab] = true;
        }
    }
}
</script>

<style scoped>

</style>
